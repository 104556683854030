import { memo, useContext } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Timer from "app/pages/.shared/Timer";
import Badge from "app/pages/.shared/Badge";
import AppGlobalsContext from "app/AppGlobalsContext";
import { STRATEGY } from "app/constants";
import BadgeList from "app/pages/.shared/ColoredProductBadge/BadgeList";

const ProductTopBar = ({
	isFlashsale = {},
	badges = [],
	productExpirationHours,
	productExpirationDays,
	showSmartDPOnRootUrl,
}) => {
	const { strategy } = useContext(AppGlobalsContext);

	if (!isFlashsale && !(showSmartDPOnRootUrl && strategy !== STRATEGY.TRANSACTION_FIRST)) {
		return false;
	}

	return (
		isFlashsale && (
			<div className="product-top-bar" data-testid="product-top-bar">
				<Badge>
					<Timer
						hour={productExpirationHours}
						day={productExpirationDays}
						suffix={<FormattedMessage id="timer.left.label" />}
					/>
				</Badge>

				<div className="product-top-bar__badges">
					<BadgeList badges={badges} />
				</div>
			</div>
		)
	);
};

ProductTopBar.propTypes = {
	isFlashsale: PropTypes.bool,
	productExpirationHours: PropTypes.number,
	badges: PropTypes.array,
	productExpirationDays: PropTypes.number,
	showSmartDPOnRootUrl: PropTypes.bool,
};

export default memo(ProductTopBar);
