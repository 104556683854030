import { createSelector } from "reselect";
import take from "lodash/take";
import forOwn from "lodash/forOwn";
import compact from "lodash/compact";
import { FacetedClassification, FacetedQuery } from "immfacet";
import {
	BRANDS,
	LISTING_ITEM_TYPES,
	OFFER_PRICE_TYPES,
	PRODUCTS_FILTERS_KEY,
	RESOLUTION,
} from "app/constants";
import { addOptionsToCloundinaryURL } from "app/utils/image/cloudinaryUtils";
import { fromJS } from "immutable";
import {
	containsTrainTransportationTypes,
	extractPreviewAfterStartDate,
	getDayCount,
} from "app/utils/utils";
import isEmpty from "lodash/isEmpty";
import {
	getFlashsaleProductsOfMerchandising,
	getListingMerchandisings,
	getMerchPreviewStartDate,
	getVisibleMerchandisings,
} from "app/pages/Merchandising/merchandisingSelector";
import { getCurrentLandingPage } from "app/pages/LandingPage/Auth/LandingAuthSelector"; // TODO : remove after ABTest
import { zonedTimeToUtc } from "date-fns-tz";
import { isAuthenticated } from "app/pages/Auth/authSelectors";

import { sortProducts } from "app/utils/utils.js";
import {
	find,
	memoize,
	includes,
	sortBy,
	orderBy,
	flatten,
	groupBy,
	get,
	uniq,
	pick,
} from "lodash";
import minBy from "lodash/minBy";

const getProducts = state => state.products;
const getProductsFilters = state => state.productsFilters;
const getTopCountries = state =>
	state.productsFilterMetaData && state.productsFilterMetaData.topCountries;
const getResolution = state => state.resolution;
const getBrand = state => state.brand.code;
const getCurrentlyViewedProduct = state => state.ficheProduit;

export const getOngoingProducts = createSelector(
	[getProducts, getMerchPreviewStartDate],
	(products = [], previewStartDate) => {
		// previewStartDate est valorisé par la query param ?preview=YYYYMMDD
		const previewAfterStartDate = extractPreviewAfterStartDate(previewStartDate);

		if (previewAfterStartDate) {
			const previewDateTimestamp = zonedTimeToUtc(previewAfterStartDate, "YYYYMMDD");

			return products.filter(product => {
				const productEndAtTimestamp = new Date(product.endAt).getTime();
				const productStartAtTimestamp = new Date(product.startAt).getTime();
				if (
					previewDateTimestamp &&
					(previewDateTimestamp <= productStartAtTimestamp &&
						previewDateTimestamp <= productEndAtTimestamp)
				) {
					return true;
				}

				return false;
			});
		}
		const previewDateTimestamp = zonedTimeToUtc(previewStartDate, "YYYYMMDD");

		return products.filter(product => {
			const productEndAtTimestamp = new Date(product.endAt).getTime();
			const productStartAtTimestamp = new Date(product.startAt).getTime();
			if (
				previewDateTimestamp &&
				(previewDateTimestamp >= productEndAtTimestamp ||
					previewDateTimestamp < productStartAtTimestamp)
			) {
				return false;
			}

			return true;
		});
	}
);

export const getProductsForHome = createSelector(
	[getOngoingProducts],
	(products = []) => {
		return take(products, 3);
	}
);

export const getProductsForLandingPage = createSelector(
	[getOngoingProducts, getCurrentLandingPage],
	(products = [], landingPage) => {
		if (get(landingPage, "productsUri.length") > 0) {
			return products.filter(product => {
				return includes(landingPage.productsUri, product.uri);
			});
		}

		return take(products, 3);
	}
);

export const getProductsByUri = (productUris = []) => {
	return createSelector(
		[getOngoingProducts],
		(products = []) => {
			const selectedProducts = productUris.map(productUri => {
				return products.find(product => {
					return product.uri === productUri;
				});
			});

			return compact(selectedProducts);
		}
	);
};

const getProductsFacetQuery = createSelector(
	[getOngoingProducts, getProductsFilters],
	(products = [], productsFilters = {}) => {
		let facetCollection = new FacetedClassification(fromJS(products));

		facetCollection = facetCollection
			.addFacet(
				PRODUCTS_FILTERS_KEY.TOPIC,
				product => {
					if (!product.get("topics")) {
						return undefined;
					}
					return product.get("topics").toJS();
				},
				{
					multiValue: true,
				}
			)
			.addFieldFacet(PRODUCTS_FILTERS_KEY.MERCHANDISINGS, {
				multiValue: true,
			})
			.addFacet(PRODUCTS_FILTERS_KEY.DESTINATION, product => {
				if (!product.get("country")) {
					return undefined;
				}
				return product.get("country");
			})
			.addFacet(
				PRODUCTS_FILTERS_KEY.ZONE,
				product => {
					if (!product.get("zones")) {
						return undefined;
					}
					return product
						.get("zones")
						.toJS()
						.map(zone => {
							return zone;
						});
				},
				{
					multiValue: true,
				}
			)
			.addFacet(
				PRODUCTS_FILTERS_KEY.CATEGORY,
				product => {
					if (!product.get("merchandisingTopics")) {
						return undefined;
					}
					return product.get("merchandisingTopics").toJS();
				},
				{
					multiValue: true,
				}
			)
			.addFacet(
				PRODUCTS_FILTERS_KEY.BADGE,
				product => {
					if (!product.get("badges")) {
						return undefined;
					}
					return product
						.get("badges")
						.toJS()
						.map(badge => {
							return badge.label;
						});
				},
				{
					multiValue: true,
				}
			)
			.addFacet(
				PRODUCTS_FILTERS_KEY.PERIOD,
				product => {
					if (!product.get("periods")) {
						return undefined;
					}
					return product
						.get("periods")
						.toJS()
						.map(period => {
							return period.label;
						});
				},
				{
					multiValue: true,
				}
			)
			.addFacet(
				PRODUCTS_FILTERS_KEY.MONTH,
				product => {
					if (!product.get("months")) {
						return undefined;
					}
					return product
						.get("months")
						.toJS()
						.map(month => {
							return String(month.startAt);
						});
				},
				{
					multiValue: true,
				}
			)
			.addFacet(PRODUCTS_FILTERS_KEY.START_AT, product => {
				if (!product.get("startAt")) {
					return undefined;
				}
				return String(
					getDayCount(product.get("startAt"), Date.now()) <= 2 &&
						getDayCount(product.get("startAt"), Date.now()) >= 0
				);
			})
			.addFacet(PRODUCTS_FILTERS_KEY.DISCOUNT_PERCENTAGE, product => {
				if (!product.get("fromPriceType")) {
					return undefined;
				}

				return String(
					product.get("fromPriceType").toJS().type ===
						OFFER_PRICE_TYPES.FROM_PRICE_TYPE_SAVE_UP_TO
						? Number(product.get("fromPriceType").toJS().value) >= 50
						: undefined
				);
			})
			.addFacet(PRODUCTS_FILTERS_KEY.END_AT, product => {
				if (!product.get("endAt")) {
					return undefined;
				}

				return String(
					getDayCount(Date.now(), product.get("endAt")) <= 2 &&
						getDayCount(Date.now(), product.get("endAt")) >= 0
				);
			});

		let facetQuery = new FacetedQuery(facetCollection);

		forOwn(productsFilters, (value, key) => {
			// on doit gérer les valeurs en string et boolean (pour startAt et endAt)
			if ((value && value.length > 0) || (value && typeof value === "boolean")) {
				facetQuery = facetQuery.select({
					name: key,
					values:
						typeof value === "string" || typeof value === "boolean" ? [value] : value,
				});
			}
		});

		return facetQuery;
	}
);

export const getVisibleProducts = createSelector(
	[getProductsFacetQuery],
	(facetQuery = {}) => {
		return sortProducts({ products: facetQuery.selectedItems().toJS() });
	}
);

export const getVisibleFilters = createSelector(
	[getProductsFacetQuery],
	(facetQuery = {}) => {
		return facetQuery.selectedFacetValues().toJS() || [];
	}
);

export const getSelectedFacets = createSelector(
	[getProductsFacetQuery],
	(facetQuery = {}) => {
		return facetQuery.selectedFacets().toJS() || [];
	}
);

export const getTopicFilterValues = createSelector(
	[getOngoingProducts],
	(products = []) => {
		if (!products || products.length === 0) {
			return [];
		}

		const topics = products.map(product => {
			return product.topics || [];
		});

		return sortBy(uniq(flatten(topics)));
	}
);

export const hasTrainTransportation = createSelector(
	[getCurrentlyViewedProduct],
	currentProduct => {
		return containsTrainTransportationTypes(currentProduct.transportationTypes);
	}
);

export const getMerchandisingTopicsFilterValues = createSelector(
	[getOngoingProducts],
	(products = []) => {
		if (!products || products.length === 0) {
			return [];
		}

		const merchandisingTopics = products.map(product => {
			return product.merchandisingTopics || [];
		});

		return sortBy(uniq(flatten(merchandisingTopics)));
	}
);

export const getDestinationFilterValues = createSelector(
	[getOngoingProducts],
	(products = []) => {
		if (!products || products.length === 0) {
			return [];
		}

		const countries = products.map(product => {
			return product.country || [];
		});

		return sortBy(uniq(flatten(countries)));
	}
);

export const getTopDestinationFilterValues = createSelector(
	[getTopCountries],
	(topCountries = []) => {
		return topCountries;
	}
);

export const getZoneFilterValues = createSelector(
	[getOngoingProducts],
	(products = []) => {
		const zones = products.map(product => {
			if (!product.zones || product.zones.length === 0) {
				return [];
			}
			return product.zones || [];
		});
		return sortBy(uniq(flatten(zones)));
	}
);

export const getPeriodFilterValues = createSelector(
	[getOngoingProducts],
	(products = []) => {
		const periods = products.map(product => {
			if (!product.periods || product.periods.length === 0) {
				return [];
			}
			return product.periods || [];
		});

		const sortedPeriods = sortBy(flatten(periods), "startAt");

		const labels = sortedPeriods.map(period => {
			return pick(period, "label").label || [];
		});

		return uniq(labels);
	}
);

export const getMonthFilterValues = createSelector(
	[getOngoingProducts],
	(products = []) => {
		const months = products.map(product => {
			if (!product.months || product.months.length === 0) {
				return [];
			}
			return product.months || [];
		});

		const sortedMonths = sortBy(flatten(months), "startAt");

		const labels = sortedMonths.map(month => {
			return String(pick(month, "startAt").startAt) || [];
		});

		return uniq(labels);
	}
);

export const getBadgeFilterValues = createSelector(
	[getOngoingProducts],
	(products = []) => {
		const badges = products.map(product => {
			if (!product.badges || product.badges.length === 0) {
				return [];
			}
			return product.badges || [];
		});

		const firstBadges = badges.map(badgeList => {
			return badgeList[0];
		});

		const sortedBadges = sortBy(flatten(compact(firstBadges)), "label");

		const badgeLabels = sortedBadges.map(badge => {
			return pick(badge, "label").label || [];
		});

		return uniq(badgeLabels);
	}
);

export const isAnyListingFilterActive = createSelector(
	[getProductsFilters],
	productsFilters => {
		return !isEmpty(productsFilters);
	}
);

/**
 * Recupere la liste des urls des images à precharger en priorite lors du chargement de la page.
 * Les urls dépendent de :
 * - resolution
 * - brand (ProductOriginal ou ProductTranslucent)
 */
export const getPrefetchedListingPhotoUrls = createSelector(
	[getOngoingProducts, getResolution, getBrand],
	(products = [], resolution, brand) => {
		const isProductTransluscent =
			brand === BRANDS.EK ||
			brand === BRANDS.DD ||
			brand === BRANDS.MS ||
			brand === BRANDS.TO;

		const productPhotoUrls = take(products, 2)
			.filter(product => product.photos && product.photos[0])
			.map(product => {
				const firstProductPhoto = product.photos && product.photos[0];

				// même options que le composant src/app/pages/Listing/components/Product.jsx
				let cloudinaryOptions = ["w_768", "h_416", "c_fill"];

				if (resolution === RESOLUTION.LARGE || resolution === RESOLUTION.XLARGE) {
					// même options que le composant src/app/pages/Listing/components/ProductFull.jsx
					cloudinaryOptions = isProductTransluscent
						? ["w_502", "h_290", "c_fill", "g_center"]
						: ["w_502", "h_240", "c_fill", "g_center"];
				}

				return addOptionsToCloundinaryURL(firstProductPhoto.url, cloudinaryOptions);
			});

		return productPhotoUrls;
	}
);

export const getPrefetchedMerchandisingPhotoUrls = createSelector(
	[getVisibleMerchandisings, getResolution, getBrand],
	(merchandisings = [], resolution, brandCode) => {
		const MERCH_COUNT = {
			AF: 3,
			EK: 3,
			TO: 1,
		};

		const MERCH_CLOUDINARY_OPTIONS = {
			AF: {
				[RESOLUTION.XLARGE]: ["w_1440", "h_550", "c_crop"],
				[RESOLUTION.LARGE]: ["w_1440", "h_550", "c_crop"],
				[RESOLUTION.MEDIUM]: ["w_768", "h_834", "c_fill", "g_center"],
				[RESOLUTION.SMALL]: ["w_768", "h_834", "c_fill", "g_center"],
			},
			EK: {
				[RESOLUTION.XLARGE]: ["w_500", "c_fill", "g_center"],
				[RESOLUTION.LARGE]: ["w_500", "c_fill", "g_center"],
				[RESOLUTION.MEDIUM]: ["w_768", "c_fill", "g_center"],
				[RESOLUTION.SMALL]: ["w_500", "c_fill", "g_center"],
			},
			TO: {
				[RESOLUTION.XLARGE]: ["w_1440"],
				[RESOLUTION.LARGE]: ["w_1440"],
				[RESOLUTION.MEDIUM]: ["w_768"],
				[RESOLUTION.SMALL]: ["w_768"],
			},
		};

		const merchCount = MERCH_COUNT[brandCode] || 0;

		const firstMerchs = take(
			orderBy(merchandisings, ["position", "headerIndex"], ["asc", "asc"]),
			merchCount
		);

		return firstMerchs.map(merch => {
			let url =
				resolution === RESOLUTION.LARGE || resolution === RESOLUTION.XLARGE
					? get(merch, "photoLarge.url")
					: get(merch, "photoSmall.url");

			return addOptionsToCloundinaryURL(url, MERCH_CLOUDINARY_OPTIONS[brandCode][resolution]);
		});
	}
);

export const getPrefetchedPhotos = createSelector(
	[getPrefetchedListingPhotoUrls, getPrefetchedMerchandisingPhotoUrls],
	(prefetchedListingPhotoUrls = [], prefetchedMerchandisingPhotoUrls = []) => {
		if (!prefetchedListingPhotoUrls) {
			return prefetchedMerchandisingPhotoUrls;
		}

		return prefetchedListingPhotoUrls.concat(prefetchedMerchandisingPhotoUrls);
	}
);

export const getListingItems = memoize(idSpotlight =>
	createSelector(
		getVisibleProducts,
		getListingMerchandisings,
		(products, merchandisings) => {
			const spotlightProduct = find(products, product => {
				return product.uri === idSpotlight;
			});

			const groupedMerchandisings = groupBy(
				spotlightProduct
					? merchandisings.map(merch => ({ ...merch, position: merch.fallbackPosition }))
					: merchandisings,
				"position"
			);

			const formattedMerchandisings = [];
			Object.entries(groupedMerchandisings).forEach(([position, merchs]) => {
				// ignore position 9 for temporary
				// ignore position 0 becasue all merch position 0 has been integrated into the listing header using the hero-merch component
				if (parseInt(position, 10) !== 9 && parseInt(position, 10) !== 0) {
					formattedMerchandisings.push(
						merchs.length > 1
							? {
									type: LISTING_ITEM_TYPES.MERCH_CAROUSSEL,
									position: parseInt(position, 10),
									merchandising: merchs,
							  }
							: {
									type: LISTING_ITEM_TYPES.MERCH_SIMPLE,
									position: parseInt(position, 10),
									merchandising: merchs[0],
							  }
					);
				}
			});
			const items = [
				...products.map(product => {
					product.type = LISTING_ITEM_TYPES.PRODUCT;
					return product;
				}),
				...formattedMerchandisings,
			].filter(item => item);
			return items;
		}
	)
);

export const getPopularProducts = createSelector(
	[getProducts],
	(products = []) => {
		return Array.isArray(products) ? products.slice(0, 3) : [];
	}
);

export const getMembershipProducts = memoize(isMerchPage =>
	createSelector(
		getOngoingProducts,
		getFlashsaleProductsOfMerchandising,
		isAuthenticated,
		(products = [], flashsaleProductsOfMerchandising = [], userIsConnected) => {
			if (isMerchPage) {
				if (flashsaleProductsOfMerchandising?.length === 0) {
					return [];
				}

				// on veut que les produits avec un prix
				const productsWithPrice = flashsaleProductsOfMerchandising.filter(
					product => Number(product?.fromPriceType?.value) > 0
				);

				// on prend le produit avec le prix le plus bas
				const cheapestProduct = minBy(productsWithPrice, product =>
					Number(product?.fromPriceType?.value)
				);

				// on prend les produits sans le produit avec le prix le plus bas
				const productsWithoutCheapest = flashsaleProductsOfMerchandising.filter(
					product => product.id !== cheapestProduct.id
				);

				// on place le produit le moins cher en faut de la liste
				const sortedProducts = [cheapestProduct, ...productsWithoutCheapest];

				if (!userIsConnected) {
					return sortedProducts.slice(0, 3);
				}
				return sortedProducts.slice(0, 4);
			}

			if (products?.length === 0) {
				return [];
			}

			if (!userIsConnected) {
				return products.slice(0, 3);
			}
			return products.slice(0, 6);
		}
	)
);
